import React, { useState } from "react";
import { Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Dashboard as Dashboard,
} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { dashboards } from '../../pages/dashlet/dataoptions';

// styles
import useStyles from "./styles";
import  "./test.css";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState(['default']);

  const handleButtonClick = () => {
      navigate('/app/dashlet');
  };

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      {props.button && (
        <div className={classes.buttonGroup} id="page-container">
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            size="large"
            color="secondary"
            onClick={handleButtonClick}
          >
            <Dashboard />&nbsp;&nbsp;<Typography>Add Dashlet</Typography>
          </Button>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            size="large"
            color="secondary"
            onClick={props.toggleDraggable}
          >
            {props.isDraggable ? <SaveIcon /> : <EditIcon />}&nbsp;&nbsp;
            <Typography>{props.isDraggable ? 'Save Dashboard' : 'Edit Dashboard'}</Typography>
        </Button>
      </div>
      )}
    </div>
  );
}
