import React from "react";
import { MenuItem, Typography } from "@mui/material";
import { QueryRenderer } from '@cubejs-client/react';
import cubejsApi from '../../../cubejs-config.js';
import spining from '../../../loading.gif'; // Ensure to import your loading spinner

export default function Export({ query, filters }) {
    let timed = filters.start_Date !== undefined && filters.start_Date !== '' ? [filters.start_Date, filters.end_Date] : query.customdashboard__filters;
    let filters1 = [];
  
    if (Array.isArray(filters.selectedCompared) && filters.selectedCompared.length > 0) {
      filters1.push({
        member: "streams_part.track_name",
        operator: "contains",
        values: filters.selectedCompared,
      });
    }
    
    if (Array.isArray(filters.selectedCountries) && filters.selectedCountries.length > 0) {
      filters1.push({
        member: "streams_part.country",
        operator: "contains",
        values: filters.selectedCountries,
      });
    }

    const handleExportCSV = (columns, data) => () => {
        const headers = columns.map(col => col.replace('streams_part.', ''));
        
        // Convert each object in data to an array of values based on columns
        const rows = data.map(row => columns.map(col => row[col]));
    
        const csvContent = headers.join(',') + '\n' +
            rows.map(row => row.join(',')).join('\n');
    
        // Create a Blob object with CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
        // Create a link element and trigger download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = query.customdashboard__title+'.csv';
        document.body.appendChild(link);
        link.click();
    
        // Cleanup
        document.body.removeChild(link);
    };
    

    return (
        <QueryRenderer
          query={{
            measures: query.customdashboard__measures,
            dimensions: query.customdashboard__dimensions,
            timeDimensions: [{
              dimension: 'streams_part.datetimestamp',
              dateRange: timed
            }],
            "filters": filters1
          }}
          cubejsApi={cubejsApi}
          render={({ resultSet, error, loading }) => {
            // if (loading) return <img src={spining} alt="Loading..." style={{ width: '100px', height: '100px' }} />;
            // if (error) return <div>Error: {error.toString()}</div>;
            // if (!resultSet) return <div>No data available</div>;
            if (resultSet) {
              const columns = [...query.customdashboard__dimensions, ...query.customdashboard__measures];
              const data = resultSet.tablePivot ? resultSet.tablePivot() : [];
      
              return (
                  <MenuItem onClick={handleExportCSV(columns, data)}>
                      <Typography>Export</Typography>
                  </MenuItem>
              );
            }else{
              return (
                <MenuItem>
                    <Typography>Export</Typography>
                </MenuItem>
              );
            }
          }}
        />
      );
}
