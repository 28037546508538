import React, { useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import {
  Box, Grid, Button
} from "@mui/material";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import DashboardTabs from './tab';
import BarCharts from "../dashboard/charts/barchart";
import ColumnCharts from "../dashboard/charts/columnchart";
import PieChart from "../dashboard/charts/piechart";
import AreaChart from "../dashboard/charts/areachart";
import LineChart from "../dashboard/charts/linechart";
import DonutChart from "../dashboard/charts/donutchart";
import Number from "../dashboard/charts/numbercard";
import Table from "../dashboard/charts/table";
import Loading from "./loading";
import axios from 'axios';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./Dashboard.css";  // Import the CSS file
import "./errorSuppress.css";  // Import the CSS file
import { useNavigate } from 'react-router-dom';

const ResponsiveGridLayout = WidthProvider(Responsive);

const fetchPieChartData = async () => {
  try {
    const response = await axios.post('https://analytics.mc-test.org/getSavedCharts.php', {
      action: '',
      chartid: '',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // Adjust content type if needed
      },
    });
    const savedCharts = response.data;
    return savedCharts;
  } catch (error) {
    console.error(error);
  }
};

const saveDashbord = async (dashboardData) => {
  try {
    const res = await axios.post('https://analytics.mc-test.org/saveChart.php',  {
      action: 'update',
      data: dashboardData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // Adjust content type if needed
      },
    });

    const UpdateCharts = res.data;
    return UpdateCharts;
    //const updateCharts = res.data.success;
    
  } catch (error) {
    console.error(error);
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Dashboard(props) {
  const [chartdata, setChartData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [chartHeights, setChartHeights] = useState({});
  const [isDraggable, setIsDraggable] = useState(false);
  const [layouts, setLayouts] = useState({});
  const [enLoad, setEnLoading] = useState(false);
  const [filters, setFilters] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    async function loadData() {
      const data = await fetchPieChartData();
      setChartData(data.data);
    }
    loadData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const onLayoutChange = (layout, allLayouts) => {
    setLayouts(layout);
    const newChartHeights = {};
    layout.forEach(item => {
      newChartHeights[item.i] = item.h * 30; // Calculate pixel height based on row height
    });
    setChartHeights(newChartHeights);
  };

  const onDragStart = (layout, oldItem, newItem, placeholder, e, element) => {
    element.classList.add('grid-item-moving');
  };

  const onDragStop = (layout, oldItem, newItem, placeholder, e, element) => {
    element.classList.remove('grid-item-moving');
  };

  const toggleDraggable = () => {
    setIsDraggable(!isDraggable);
    if (isDraggable) {
      saveLayout();
    }
  };

  const saveLayout = async () => {
    try {
      setEnLoading(true);
      const update = await saveDashbord(layouts);
      if (update) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Failed to save layout:', error);
      setEnLoading(false); // Stop loading if there is an error
    }
  };

  const handleFilterApply = (chartID, filterValues) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [chartID]: filterValues,
    }));
  };

  return (
    <>
      {enLoad ? (<><Loading /></>) : (<></>) }
      <DashboardTabs value={tabIndex} handleChange={handleTabChange} />
      {chartdata.length === 0 ? (
        <>
          <PageTitle title="Dashboard" button="Latest Reports" toggleDraggable={toggleDraggable} />
          <Loading />
        </>
      ) : (
        <>
          {Object.keys(chartdata).map((tabId, index) => {
            const tabData = chartdata[tabId];
            const tabDataArray = Array.isArray(tabData) ? tabData : Object.values(tabData);
            const layout = tabDataArray.map((item, idx) => ({
              i: item.customdashboard__chart_id.toString(),
              x: item.customdashboard__x == null ? 0 : item.customdashboard__x, // 2 columns
              y: item.customdashboard__y == null ? 1000 : item.customdashboard__y,
              w: item.customdashboard__x == null ? 6 : item.width,// default 6
              h: item.customdashboard__x == null ? 16 : item.height,// default 12 , number card - 9
              minW: 3, // Minimum width
              maxW: 15, // Maximum width
              minH: item.customdashboard__chart_type === 'number' ? 8 : 16, // Minimum height
              maxH: 30, // Maximum height
            }));          
           // console.log(layout);

            const layouts = {
              lg: layout,
              md: layout,
              sm: layout,
              xs: layout,
              xxs: layout
            };

            return (
              <TabPanel value={tabIndex} index={index} key={index}>
                <PageTitle title="Dashboard" button="Latest Reports" toggleDraggable={toggleDraggable} isDraggable={isDraggable} />
                <ResponsiveGridLayout
                  className="layout"
                  layouts={layouts}
                  breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                  cols={{ lg: 15, md: 10, sm: 6, xs: 4, xxs: 2 }}
                  rowHeight={30}
                  onLayoutChange={onLayoutChange}
                  onDragStart={onDragStart}
                  onDragStop={onDragStop}
                  isDraggable={isDraggable} // Use the state variable
                  isResizable={isDraggable} // Use the state variable
                >
                  {tabDataArray.map((data, idx) => {
                    const chartKey = `${data.customdashboard__chart_id}`;
                    const gridItem = layout[idx];
                    const pixelHeight = chartHeights[gridItem.i] || gridItem.h * 30;
                    return (
                      <div key={chartKey} data-grid={gridItem} className="grid-item">
                        <Widget
                          title={data.customdashboard__title}
                          chartID={data.customdashboard__chart_id}
                          onFilterApply={handleFilterApply}
                          chartType={data.customdashboard__chart_type}
                          chartData={data}
                          filters={filters[data.customdashboard__chart_id] || {}}
                        >
                          {data.customdashboard__chart_type === 'pie' && <PieChart query={data} height={pixelHeight} filters={filters[data.customdashboard__chart_id] || {}} />}
                          {data.customdashboard__chart_type === 'column' && <ColumnCharts query={data} height={pixelHeight} filters={filters[data.customdashboard__chart_id] || {}} />}
                          {data.customdashboard__chart_type === 'bar' && <BarCharts query={data} height={pixelHeight} filters={filters[data.customdashboard__chart_id] || {}} />}
                          {data.customdashboard__chart_type === 'area' && <AreaChart query={data} height={pixelHeight} filters={filters[data.customdashboard__chart_id] || {}} />}
                          {data.customdashboard__chart_type === 'line' && <LineChart query={data} height={pixelHeight} filters={filters[data.customdashboard__chart_id] || {}} />}
                          {data.customdashboard__chart_type === 'piering' && <DonutChart query={data} height={pixelHeight} filters={filters[data.customdashboard__chart_id] || {}} />}
                          {data.customdashboard__chart_type === 'number' && <Number query={data} height={pixelHeight} filters={filters[data.customdashboard__chart_id] || {}} index={idx} />}
                          {data.customdashboard__chart_type === 'table' && <Table query={data} height={pixelHeight} filters={filters[data.customdashboard__chart_id] || {}} />}
                        </Widget>
                      </div>
                    );
                  })}
                </ResponsiveGridLayout>
              </TabPanel>
            );
          })}
        </>
      )}
    </>
  );
}
