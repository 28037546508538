import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
  },
  buttonGroup: {
    marginLeft: "auto",
    display: "flex",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    }, 
  },
  typo: {
    color: theme.palette.text.hint,
  },
  button: {
    fontSize: 'unset !important',
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
}));
