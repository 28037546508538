import React from "react";
import {
  Box, CssBaseline, LinearProgress
} from "@mui/material";

export default function Dashboard(props) {
    return (
        <>
            <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                position="fixed"
                top="8%"
                left="0"
                width="100%"
                height="95%"
                bgcolor="rgba(255, 255, 255, 0.8)" // Light overlay
                zIndex={9999} // Ensure it overlays all content
            >
                <CssBaseline />
                <Box width="20%">
                        <LinearProgress />
                </Box>
            </Box>
        </>
    );
}