import React from "react";
import ApexCharts from "react-apexcharts";
import { QueryRenderer } from '@cubejs-client/react';
import cubejsApi from '../../../cubejs-config.js';
import spining from '../../../loading.gif';
import colors from './colorPalette.js'; 
import {
  ResponsiveContainer,
} from "recharts";

export default function DonutChart(props) {
  let timed = props.query.customdashboard__filters;
  if(props.filters.start_Date != undefined && props.filters.start_Date != ''){
    timed = [props.filters.start_Date, props.filters.end_Date];
  }
  let filters = [];

  if (Array.isArray(props.filters.selectedCompared) && props.filters.selectedCompared.length > 0) {
    filters.push({
      member: "streams_part.track_name",
      operator: "contains",
      values: props.filters.selectedCompared,
    });
  }
  
  if (Array.isArray(props.filters.selectedCountries) && props.filters.selectedCountries.length > 0) {
    filters.push({
      member: "streams_part.country",
      operator: "contains",
      values: props.filters.selectedCountries,
    });
  }
    return (
      <QueryRenderer
        query={{
          measures: props.query.customdashboard__measures,
          dimensions: props.query.customdashboard__dimensions,
          timeDimensions: [{
            dimension: 'streams_part.datetimestamp',
            dateRange: timed
          }],
          "filters": filters
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet, error, loading }) => {
          if (loading) return <img src={spining} alt="Loading..." style={{ width: '100px', height: '100px' }} />;
          if (error) return <div>{error.toString()}</div>;

          if (!resultSet) {
            return <img src={spining} alt="Loading..." style={{ width: '100px', height: '100px' }} />;
          }

          const data = resultSet.chartPivot();
          if (!data) {
            return <div>No data to display.</div>;
          }

          const chartData = data.map(x => ({
            name: x.x,
            y: parseFloat(x[props.query.customdashboard__measures])
          }));

          const options = {
            labels: chartData.map(d => d.name),
            colors: colors,
            legend: {
              show: props.query.customdashboard__legend == 1 ? true : false,
              position: 'bottom', // Set the legend to be at the bottom
              horizontalAlign: 'center'
            },
            tooltip: {
              enabled: props.query.customdashboard__tooltip == 1 ? true : false,
            },
            plotOptions: {
              pie: {
                donut: {
                  size: '45%'
                }
              }
            }
          };

          return (
            <ResponsiveContainer width="100%"  height={props.height}>
            <ApexCharts
              options={options}
              series={chartData.map(d => d.y)}
              type="donut"
              height={350}
            />
            </ResponsiveContainer>
          );
        }}
      />
    );
}
