import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo
import logo from "./b2blogo.png";
import logo1 from "./b2blogo1.png";

// context
import { useUserDispatch, useUserState, loginUser } from "../../context/UserContext";

function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  
  // global
  const userDispatch = useUserDispatch();
  const { error } = useUserState();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [localError, setLocalError] = useState(null);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  
  

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        {/* <Typography className={classes.logotypeText}>B2B Customer</Typography> */}
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          {(
            <React.Fragment>
              <img src={logo} alt="google" className={classes.googleIcon} />
              <Typography variant="h3" style={{ color: '#1345ac', fontWeight: 'bold',textAlign:'center' }} >
                  Login
              </Typography>
              <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error}
              </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        navigate,
                        setIsLoading,
                        setLocalError,
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
                {/* <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                >
                  Forget Password
                </Button> */}
              </div>
            </React.Fragment>
          )}
        </div>
        {/* <Typography color="primary" className={classes.copyright}>
          © 2014-2019 Flatlogic, LLC. All rights reserved.
        </Typography> */}
      </div>
    </Grid>
  );
}

export default Login;
