import React, { useState } from "react";
import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import classnames from "classnames";
import  Delete from "../../pages/dashboard/action/deletechart";
import  Filter from "../../pages/dashboard/action/filter";
import  Export from "../../pages/dashboard/action/export";
import { useNavigate } from 'react-router-dom';

// styles
import useStyles from "./styles";

export default function Widget({
  children,
  title,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  header,
  chartID,
  onFilterApply,
  chartType,
  chartData,
  filters,
  ...props
}) {
  var classes = useStyles();
  const navigate = useNavigate();
  
  // local
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  const handleEdit = async (action) => {
    const url = new URL(window.location.href);
    if(action == 'Edit'){
      url.pathname = '/app/dashlet/'+chartID+'/edit';
      navigate(`${url.pathname}`);
    }

    if(action == 'Duplicate'){
      url.pathname = '/app/dashlet/'+chartID+'/duplicate';
      navigate(`${url.pathname}`);
    }
  };
console.log(chartType);
  return (
    <div className={classes.widgetWrapper}>
      <Paper className={classes.paper} classes={{ root: classes.widgetRoot }}>
        <div className={classes.widgetHeader}>
          {header ? (
            header
          ) : (
            <React.Fragment>
              <Typography variant="h5" color="textSecondary">
                {title}
              </Typography>
              {!disableWidgetMenu && (
                <IconButton
                  color="primary"
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={() => setMoreMenuOpen(true)}
                  ref={setMoreButtonRef}
                >
                  <MoreIcon />
                </IconButton>
              )}
            </React.Fragment>
          )}
        </div>
        <div
          className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
          })}
        >
          {children}
        </div>
      </Paper>
      <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem onClick={() => handleEdit('Edit')}>
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleEdit('Duplicate')}>
          <Typography>Duplicate</Typography>
        </MenuItem>
        <Delete chartID={chartID} />
        <Filter chartID={chartID} onFilterApply={onFilterApply} />
        {chartType == 'table' && ( <Export chartID={chartID} query={chartData}  filters={filters} /> )}
      </Menu>
    </div>
  );
}
