
import  {PieChart,ShowChart,BarChart as BarIcon,DonutLarge, Info} from "@mui/icons-material";
import TableChartIcon from '@mui/icons-material/TableChart';

export const chartTypes = [
    { value: 'line', icon: <ShowChart />, label: 'Line Chart' },
    { value: 'area', icon: <ShowChart />, label: 'Area Chart' },
    { value: 'bar', icon: <BarIcon />, label: 'Bar Chart' },
    { value: 'column', icon: <BarIcon />, label: 'Column Chart' },
    { value: 'pie', icon: <PieChart />, label: 'Pie Chart' },
    { value: 'piering', icon: <DonutLarge />, label: 'Pie Ring Chart' },
    { value: 'table', icon: <TableChartIcon />, label: 'Table' },
    { value: 'number', icon: <Info />, label: 'Number' },
  ];
  
  export const measures = [
    { value: 'streams_part.totaltrackcount', label: 'Count Of Streams' },
    { value: 'streams_part.countTrackId', label: 'Count Of Tracks' },
    { value: 'streams_part.countUserId', label: 'Count Of Listeners' },
    // { value: 'streams_part.totaltracksum', label: 'Sum Of Streams' },
    // { value: 'streams_part.sumTrackId', label: 'Sum Of Tracks' },
    // { value: 'streams_part.sumUserId', label: 'Sum Of Listeners' },
    // { value: 'saves', label: 'Saves' },
    // { value: 'skips', label: 'Skips' }
  ];
  
  export const dimensions = [
    { value: 'streams_part.track_name', label: 'Track Name' },
    { value: 'streams_part.album_artist', label: 'Artist' },
    { value: 'streams_part.album_name', label: 'Album Name' },
    { value: 'streams_part.source', label: 'Source' },
    { value: 'streams_part.device_type', label: 'Device Type' },
    { value: 'streams_part.age_group', label: 'Age Group' },
    { value: 'streams_part.gender', label: 'Gender' },
    { value: 'streams_part.completion_flag', label: 'Completion' },
    { value: 'streams_part.product', label: 'Product' },
    { value: 'streams_part.access', label: 'Access' },
    { value: 'streams_part.discovery_flag', label: 'Discovery Type' },
    { value: 'streams_part.content_type_flag', label: 'Content Type' },
    { value: 'streams_part.country', label: 'Country' },
    { value: 'streams_part.os', label: 'OS' },
    { value: 'streams_part.repeat_play', label: 'Repeat Play' },
    { value: 'streams_part.isrc', label: 'ISRC' },
    { value: 'streams_part.uri', label: 'URI' },
    { value: 'streams_part.type', label: 'Type' },
    { value: 'streams_part.album_code', label: 'Album Code' },
  ];
  
  export const filters = [
    // { value: "Today", label: "Today" },
    // { value: "Yesterday", label: "Yesterday" },
    { value: "This Week", label: "This Week" },
  //   { value: "This Month", label: "This Month" },
  //   { value: "Last Week", label: "Last Week" },
  //   { value: "Last Month", label: "Last Month" },
   ];
  
  export const parameters = [
    { value: 'Date Range', label: 'Date Range' },
    { value: 'Country', label: 'Country' },
    { value: 'Track Name', label: 'Track Name' },
    { value: 'Albumn Name', label: 'Albumn Name' },
  ];
  
  export const dashboards = [
    { value: 'Default', label: 'Default' },
    { value: 'default1', label: 'Tab Dashboard 1' },
    { value: 'default2', label: 'Tab Dashboard 2' },
  ];
  