import React, { useState, useEffect } from 'react';
import { Tabs, Tab, useTheme, useMediaQuery, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// components
import { Typography } from "../../components/Wrappers";

const getTabData = async () => {
  try {
    const response = await axios.post('https://analytics.mc-test.org/getSavedCharts.php',  {
      action: 'getTab',
      chartid: '',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const tabdatas = response.data;
    return tabdatas;
  } catch (error) {
    console.error(error);
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
  },
  dialog: {
    position: 'absolute',
    top: 'calc(100% + 8px)',
    left: 0,
    zIndex: theme.zIndex.tooltip + 1,
    '& .MuiBackdrop-root': {
      // backgroundColor: 'transparent',
    },
  },
  closeButton: {
    position: 'absolute',
    top: 0
  },
  dialogclose: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textfield: {
    width: '325px',
  },
  dialogcontent: {
    paddingTop: '2% !important'
  },
  addTab: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function DashboardTabs({ value, handleChange }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tabname, setTabName] = useState('');
  const [tabdata, setTabData] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true); // State to control save button

  useEffect(() => {
    async function loadData() {
      const data = await getTabData();
      setTabData(data);
    }
    loadData();
  }, []);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setTabName(value);
    // Enable save button if there is text
    setSaveDisabled(value.trim() === '');
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTabName(''); // Clear tabname on close
    setSaveDisabled(true); // Disable save button when closing
  };

  const handleSave = async () => {
    try {
      const response = await axios.post('https://analytics.mc-test.org/saveChart.php',  {
        tabname: tabname,
        action: 'tab',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      if (response) {
        const url = new URL(window.location.href);
        url.pathname = '/app/dashlet';
        navigate(`${url.pathname}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.container}>
      {tabdata === '' ? (
        <Tabs
          value={value}
          onChange={handleChange}
          variant={isSmallScreen ? 'scrollable' : 'standard'}
          scrollButtons="auto"
          aria-label="dashboard tabs"
        >
          <Tab label="Default" />
        </Tabs>
      ) : (
        <Tabs
          value={value}
          onChange={handleChange}
          variant={isSmallScreen ? 'scrollable' : 'standard'}
          scrollButtons="auto"
          aria-label="dashboard tabs"
        >
          {tabdata.map((type, index) => (
            <Tab label={type.tab_name} key={index}><Typography>{type.tab_name}</Typography></Tab>
          ))}
        </Tabs>
      )}
      <IconButton aria-label="add tab" color="primary" onClick={handleClickOpen} className={classes.addTab}>
        <AddIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogTitle className={classes.dialogclose}>Add Tab<IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton></DialogTitle>
        <DialogContent className={classes.dialogcontent}>
          <TextField
            label="Tab"
            variant="outlined"
            onChange={handleInputChange}
            value={tabname}
            className={classes.textfield}
            name="Tab Name"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary" disabled={saveDisabled}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
