import React from "react";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, error: null };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false, error: action.error };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, error: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
    error: null,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

function loginUser(dispatch, login, password, navigate, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  const users = [
    { username: "mattnash@routenote", password: "n3v&5AyL9t2$Snnk" },
    { username: "soave@routenote", password: "I#CkJwSB5lHgJUue" },
    // Add more users as needed
  ];

  const user = users.find(
    (user) => user.username === login && user.password === password
  );

  if (user) {
    setTimeout(() => {
      localStorage.setItem("id_token", "1");
      dispatch({ type: "LOGIN_SUCCESS" });
      setError(null);
      setIsLoading(false);
      navigate("/app/dashboard");
    }, 2000);
  } else {
    setTimeout(() => {
      dispatch({ type: "LOGIN_FAILURE", error: "Something is wrong with your login or password :(" });
      setError(true);
      setIsLoading(false);
    }, 2000);
  }
}

function signOut(dispatch, navigate) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  navigate("/login");
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };
