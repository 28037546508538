import React from "react";
import { BarChart, Bar, ResponsiveContainer } from "recharts";
import { QueryRenderer } from '@cubejs-client/react';
import cubejsApi from '../../../cubejs-config.js';
import { Typography } from "../../../components/Wrappers";
import useStyles from "./../styles";
import { useTheme } from "@mui/styles";
import spining from '../../../loading.gif';
import colors from './colorPalette'; 

const CustomWidget = (props) => {
  let timed = props.query.customdashboard__filters;
  if(props.filters.start_Date != undefined && props.filters.start_Date != ''){
    timed = [props.filters.start_Date, props.filters.end_Date];
  }
  let filters = [];

  if (Array.isArray(props.filters.selectedCompared) && props.filters.selectedCompared.length > 0) {
    filters.push({
      member: "streams_part.track_name",
      operator: "contains",
      values: props.filters.selectedCompared,
    });
  }
  
  if (Array.isArray(props.filters.selectedCountries) && props.filters.selectedCountries.length > 0) {
    filters.push({
      member: "streams_part.country",
      operator: "contains",
      values: props.filters.selectedCountries,
    });
  }
  var classes = useStyles();
  var theme = useTheme();
  return (
    <QueryRenderer
      query={{
        measures: props.query.customdashboard__measures,
        timeDimensions: [{
          dimension: 'streams_part.datetimestamp',
          granularity: 'day',
          dateRange: timed
        }],
        dimensions: props.query.customdashboard__dimensions,
        "filters": filters
      }}
      cubejsApi={cubejsApi}
      render={({ resultSet, error, loading }) => {
        if (loading) return <img src={spining} alt="Loading..." style={{ width: '100px', height: '100px' }} />;
        if (error) return <div>{error.toString()}</div>;
        if (!resultSet) return <img src={spining} alt="Loading..." style={{ width: '100px', height: '100px' }} />;

        const total = resultSet.series()[0]?.series?.reduce((acc, curr) => acc + curr.value, 0) || 0;
        const data = resultSet.series().flatMap(s => s.series.map(point => ({ value: point.value })));
        if (!total) return <div>No data available</div>;
        return (
          <ResponsiveContainer width="100%" >
            <div className={classes.visitsNumberContainer}>
              <Typography size="xl" weight="medium">
                {format(total)}
              </Typography>
              <BarChart width={250} height={130} data={data}>
                <Bar
                  dataKey="value"
                  fill={colors[props.index]}
                  radius={10}
                  barSize={10}
                />
              </BarChart>
            </div>
          </ResponsiveContainer>
        );
      }}
    />
  );
};

function format(value) {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(2) + ' M';
  } else if (value >= 1000) {
    return (value / 1000).toFixed(2) + ' k';
  } else {
    return value.toString();
  }
}

export default CustomWidget;
