import {
    red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan,
    teal, green, lightGreen, lime, yellow, amber, orange, deepOrange,
    brown, grey, blueGrey
  } from '@mui/material/colors';
  
  // Function to generate a comprehensive list of colors
  function generateColorPalette() {
    const baseColors = [
      red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan,
      teal, green, lightGreen, lime, yellow, amber, orange, deepOrange,
      brown, grey, blueGrey
    ];
  
    const shades = [300,400,500,600,700,800,900]; // Common shades
    const colors = [];
  
    shades.forEach(shade => {
      baseColors.forEach(baseColor => {
          if (baseColor[shade]) {
            colors.push(baseColor[shade]);
          }
      });
    });
  
    return colors.slice(0, 100); // Limit to the first 100 colors if more
  }
  
  const colors = generateColorPalette();
  
  // Exporting colors for use in your dashboard components
  export default colors;
  