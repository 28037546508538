import cubejs from '@cubejs-client/core';

const API_URL = process.env.REACT_APP_CUBEJS_API_URL; // Your Cube.js API URL
const API_TOKEN = process.env.REACT_APP_CUBEJS_API_TOKEN; // Your Cube.js API Token

const cubejsApi = cubejs(API_TOKEN, {
  apiUrl: `${API_URL}`
});

export default cubejsApi;
