import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
  Checkbox,
  ListItemText
  } from '@mui/material';
import { SketchPicker } from 'react-color';
import { useNavigate, useParams } from 'react-router-dom';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

import axios from 'axios';
import AddchartIcon from '@mui/icons-material/Addchart';
// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import  BarCharts from "../dashboard/charts/barchart";
import  ColumnCharts from "../dashboard/charts/columnchart";
import  PieChart from "../dashboard/charts/piechart";
import  AreaChart from "../dashboard/charts/areachart";
import  LineChart from "../dashboard/charts/linechart";
import  DonutChart from "../dashboard/charts/donutchart";
import  TableChart from "../dashboard/charts/table";
import  NumberChart from "../dashboard/charts/numbercard";
import  Loading from "../dashboard/loading";
import { chartTypes, measures, dimensions, filters, parameters, dashboards } from './dataoptions';
import { Legend } from "recharts";
import Chatbot from "./Chatbot";

const getTabData = async () => {
  try {
    const response = await axios.post('https://analytics.mc-test.org/getSavedCharts.php',  {
      action: 'getTab',
      chartid: '',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // Adjust content type if needed
      },
    });
    const tabdatas = response.data;
    return tabdatas;
  } catch (error) {
    console.error(error);
  }
};

const editChart = async (id) => {
  try {
    const response = await axios.post('https://analytics.mc-test.org/getSavedCharts.php',  {
      action: 'Edit',
      chartid: id,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // Adjust content type if needed
      },
    });
    const editdata = response.data;
    return editdata;
  } catch (error) {
    console.error(error);
  }
};

export default function Dashlet({props}) {

  var classes = useStyles();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [chartType, setChartType] = useState('');
  const [measure, setMeasure] = useState([]);
  const [tabdata, setTabData] = useState([]);
  const [dimention, setDimention] = useState([]);
  const [filterOption, setFilterOption] = useState('This Week');
  const [parameterOption, setParameterOption] = useState([]);
  const [dashboard, setDashboard] = useState('');
  const [showTooltip, setShowTooltip] = useState(true);
  const [showLegend, setShowLegend] = useState(true);
  const [run, showRun] = useState(false);
  const [exe, setExe] = useState(false);
  const [Load, setLoading] = useState(true);
  const { id, action } = useParams();
  const [invisibleChartType, setInvisibleChartType] = useState('');
  const [invisibleMeasure, setInvisibleMeasure] = useState('');
  const [invisibleDimension, setInvisibleDimension] = useState('');
  const [chartData, setChartData] = useState(null);
  
  useEffect(() => {
    async function loadData() {
      const data = await getTabData();
      setTabData(data);

      const getchartdata = await editChart(id);
      if(id){
        //console.log(getchartdata[0].customdashboard__chart_id);
        setTitle(getchartdata[0].customdashboard__title);
        setChartType(getchartdata[0].customdashboard__chart_type);
        setMeasure(getchartdata[0].customdashboard__measures);
        setDimention(getchartdata[0].customdashboard__dimensions);
        setDashboard(getchartdata[0].customdashboard__tab_id);
        setLoading(false);
        showRun(true);
      }else{
        setLoading(false);
      }
    }
    loadData();
  }, []);


  useEffect(() => {
    if (exe) {
      handlerunChart();
    }
  }, [exe]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'chartType':
        setChartType(value);
        break;
      case 'measure':
        setMeasure(value);
        break;
      case 'dimention':
        setDimention(value);
        break;
      case 'dashboard':
        setDashboard(value);
        break;
      case 'filter':
        setFilterOption(value);
        break;
      case 'parameter':
        setParameterOption(value);
        break;
      default:
        break;
    }
    if (chartType && dashboard && (measure || dimention)) {
      //showRun('true');
      handlerunChart();
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target; // Destructure both name and checked properties
    switch (name) {
      case 'showTooltip':
        setShowTooltip(checked); // Set the state to the value of checked
        break;
      case 'showLegend':
        setShowLegend(checked); // Set the state to the value of checked
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let filter = {};
    if(chartType == 'line' || chartType == 'area'){
      filter = [{
        dimension: 'streams_part.datetimestamp',
        granularity: 'day',
        dateRange: ['2024-05-18', '2024-05-24']
      }];
    }else{
      filter = [{
        dimension: 'streams_part.datetimestamp',
        dateRange: ['2024-05-18', '2024-05-24']
      }];
    }

      // Use invisible states if they are set, otherwise use visible states
  const chartTypeToUse = invisibleChartType || chartType;
  const measureToUse = invisibleMeasure || measure;
  const dimensionToUse = invisibleDimension || dimention;

  console.log('Submitting form with:', { chartType, measure, dimention });


  // Check if we have all required fields
  if (!chartTypeToUse || !measureToUse || !dimensionToUse) {
    alert("Please ensure Chart Type, Measure, and Dimension are set.");
    return;
  }

    let chart_id = '';
    let chart_action = '';
    if(id){
      chart_id = id;
      chart_action = action;
    }else{
      chart_id = '';
      chart_action = 'insert';
    }
    
    try {
      const response = await axios.post('https://analytics.mc-test.org/saveChart.php',  {
        chartType: chartTypeToUse,
        dimension: dimensionToUse,
        measure: measureToUse,
        title: title,
        tooltip: showTooltip,
        legend: showLegend,
        action: chart_action,
        chartid: chart_id,
        filter: ['2024-05-18', '2024-05-24'],
        tabId: dashboard,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Adjust content type if needed
        },
      });
	    if(response){
        const url = new URL(window.location.href);
        url.pathname = '/app/dashboard';
        navigate(`${url.pathname}`);
      }
    
    } catch (error) {
      console.error(error);
    }

  };

  const handlerunChart = () => {
    // Use invisible states if they are set, otherwise use visible states
    const chartTypeToUse = invisibleChartType || chartType;
    const measureToUse = invisibleMeasure || measure;
    const dimensionToUse = invisibleDimension || dimention;
  
    console.log('Updating chart with:', {
      chartType: chartTypeToUse,
      measure: measureToUse,
      dimension: dimensionToUse
    });
  
    // Prepare the data object for the chart
    const data = {
      customdashboard__title: title,
      customdashboard__measures: measureToUse,
      customdashboard__dimensions: dimensionToUse,
      customdashboard__filters: ["2024-05-18", "2024-05-24"],
      customdashboard__chart_type: chartTypeToUse,
      customdashboard__tooltip: showTooltip,
      customdashboard__legend: showLegend,
    };
  
    // Update the chart data state
    setChartData(data);
  
    // Set exe to true to trigger chart rendering
    setExe(true);
  
    // Reset run state
    showRun(false);
  
    // // Reset invisible states after using them
    // setInvisibleChartType('');
    // setInvisibleMeasure('');
    // setInvisibleDimension('');
  
    console.log('Chart update triggered');
  };

  const renderChart = () => {
    if (!chartData) {
      console.log('No chart data available');
      return null;
    }

    console.log('Rendering chart with data:', chartData);

    switch (chartData.customdashboard__chart_type) {
      case 'pie':
        return (
          <>
            <PieChart query={chartData} height="350" filters={{}} />
          </>
        );
      case 'column':
        return (
          <>
            <ColumnCharts query={chartData} height="350" filters={{}} />
          </>
        );
      case 'bar':
        return (
          <>
            <BarCharts query={chartData} height="350" filters={{}} />
          </>
        );
      case 'area':
        return (
          <>
            <AreaChart query={chartData} height="350" filters={{}} />
          </>
        );
      case 'line':
        return (
          <>
            <LineChart query={chartData} height="350" filters={{}} />
          </>
        );
      case 'piering':
        return (
          <>
            <DonutChart query={chartData} height="350" filters={{}} />
          </>
        );
      case 'table':
        return (
          <>
            <TableChart query={chartData} height="350" filters={{}} />
          </>
        );
      case 'number':
        return (
          <>
            <NumberChart query={chartData} height="350" filters={{}} index="1" />
          </>
        );
      default:
        return null;
    }
  };


  const handleUserInput = (message) => {
    console.log('Received message in Dashlet:', message);

    let shouldUpdateChart = false;
  
    if (message.chartType) {
      console.log('Setting invisible chart type to', message.chartType);
      setInvisibleChartType(message.chartType);
      shouldUpdateChart = true;
    }
  
    if (message.dimension) {
      console.log('Setting invisible dimension to', message.dimension.map(dim => 'streams_part.' + dim));
      setInvisibleDimension(message.dimension.map(dim => 'streams_part.' + dim));
      shouldUpdateChart = true;
    }
  
    if (message.measures) {
      console.log('Setting invisible measure to', message.measures.map(meas => 'streams_part.' + meas));
      setInvisibleMeasure(message.measures.map(meas => 'streams_part.' + meas));
      shouldUpdateChart = true;
    }
  
    if (shouldUpdateChart) {
      setExe(true);
      showRun(false);
    }
  };

  return (
    <> {Load ? (<Loading />) :(<></>)}
      <PageTitle title="Add Dashlet" />
      <Grid container spacing={4}>
      <Grid item xs={12}  md={6}>
      <form onSubmit={handleSubmit}>
      {/* <Typography variant="h5" gutterBottom>
        Create a Chart
      </Typography> */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            label="Chart Title"
            variant="outlined"
            fullWidth
            onChange={handleInputChange}
            value={title}
            name="title"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="chart-type-label" className={classes.addChartLabel}>Chart Type</InputLabel>
            <Select
              labelId="chart-type-label"
              onChange={handleInputChange}
              value={chartType}
              name="chartType"
              
            >
              {chartTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  <IconButton color="inherit">
                    {type.icon}
                  </IconButton>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="dashboard-option-label" className={classes.addChartLabel}>Dashboard</InputLabel>
            <Select
              labelId="dashboard-option-label"
              onChange={handleInputChange}
              value={dashboard}
              name="dashboard"
              required
            >
             {tabdata.map((type) => (
                <MenuItem key={type.tab_id} value={type.tab_id}>
                  {type.tab_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel id="measures-label" className={classes.addChartLabel}>Measures</InputLabel>
        <Select
          labelId="measures-label"
          onChange={handleInputChange}
          multiple
          value={measure}
          name="measure"
          renderValue={(selected) => 
            selected.map(value => measures.find(mes => mes.value === value).label).join(', ')
          }
        >
          {measures.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              <Checkbox checked={parameterOption.indexOf(type.value) > -1} />
              <ListItemText primary={type.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
        <Grid item xs={12} md={6}>
        <FormControl fullWidth>
            <InputLabel id="dimensions" className={classes.addChartLabel}>Dimensions</InputLabel>
            <Select
              labelId="dimensions"
              onChange={handleInputChange}
              multiple
              value={dimention}
              renderValue={(selected) => 
                selected.map(value => dimensions.find(dim => dim.value === value).label).join(', ')
              }
              name="dimention"
            >
              {dimensions.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  <Checkbox checked={parameterOption.indexOf(type.value) > -1} />
                  <ListItemText primary={type.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="filter-option-label" className={classes.addChartLabel}>Filter</InputLabel>
            <Select
              labelId="filter-option-label"
              onChange={handleInputChange}
              value={filterOption}
              name="filter"
            >
             {filters.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="parameter-option-label" className={classes.addChartLabel}>Parameters</InputLabel>
            <Select
              labelId="Parameters-option-label"
              onChange={handleInputChange}
              multiple
              value={parameterOption}
              renderValue={(selected) => selected.join(', ')}
              name="parameter"
            >
             {parameters.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  <Checkbox checked={parameterOption.indexOf(type.value) > -1} />
                  <ListItemText primary={type.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <InputLabel id="filter-option-label" >Color Theme:</InputLabel>
          <SketchPicker color={color} onChangeComplete={handleColorChange} />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={<Checkbox checked={showTooltip} name="showTooltip" onChange={handleCheckboxChange} />}
            label="Show Tooltip"
          />
          <FormControlLabel
            control={<Checkbox checked={showLegend} name="showLegend" onChange={handleCheckboxChange} />}
            label="Show Legend"
          />
        </Grid>
        <Grid item xs={12}>
          {action == 'edit' ? (<Button type="submit" variant="contained" color="primary">
            Update Chart
          </Button> ) : (
          <Button type="submit" variant="contained" color="primary">
            Create Chart
          </Button> )}
        </Grid>
      </Grid>
    </form>
      </Grid>
      <Grid item xs={12} md={6}>
        <Widget disableWidgetMenu>
          <div className={classes.addChart}>
            {run ?  (
              <Button type="submit" variant="contained" color="primary" className={classes.addChartTypography} onClick={handlerunChart}>
                <PlayCircleFilledIcon /> &nbsp;&nbsp;Run
              </Button> 
            ) : ( <>
              {exe && chartData ?  (<>{renderChart()}</>
              ) : (
              <>
                <AddchartIcon className={classes.addChartIcon} />
                <Typography className={classes.addChartTypography} >
                  Choose a measure or dimension to get started
                </Typography>
              </> 
              )} </>
            )}
          {/* <Gauge /> */}
          </div>
        </Widget>
      </Grid>
      <Chatbot onUserInput={handleUserInput}/>
      </Grid>
    </>
  );
}

