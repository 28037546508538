import React from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Dashlet from "../../pages/dashlet";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout() {
  var classes = useStyles();
  var history = useNavigate();
  var location = useLocation();
  const isDashletRoute = location.pathname.includes('/app/dashlet');
  
  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          {location.pathname == '/app/dashboard' ? (
            <Dashboard />
          ) : (
            <></>
          )}
          {isDashletRoute ? (
            <Dashlet />
          ) : (
            <></>
          )}
          
         {/*  <Routes>
            <Route path="/app/dashboard" element={<Dashboard />} />
            <Route path="/app/dashlet" element={<Dashlet />} />
          </Routes> */}
        </div>
      </>
    </div>
  );
}

export default Layout;
