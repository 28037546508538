import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  addChart: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  addChartIcon: {
    fontSize: '200px !important',
    color: '#dcdcdc',
    border: 'dashed 3px #dcdcdc',
    [theme.breakpoints.only("xs")]: {
      fontSize: "100px !important",
    },
  },
  addChartTypography: {
    marginTop: '16px !important',
    fontSize: '17px !important',
    [theme.breakpoints.only("xs")]: {
      fontSize: '11px !important',
    },
  },
  addChartLabel: {
    background: '#ffffff',
    padding: '0px 5px 0px 5px !important'
  },
}));
