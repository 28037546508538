import React from "react";
import ApexCharts from "react-apexcharts";
import { QueryRenderer } from '@cubejs-client/react';
import cubejsApi from '../../../cubejs-config.js';
import spining from '../../../loading.gif';
import colors from './colorPalette'; 
import format from './numberFormat'; 
import {
  ResponsiveContainer,
} from "recharts";

export default function LineCharts(props) {
  let timed = props.query.customdashboard__filters;
  if(props.filters.start_Date != undefined && props.filters.start_Date != ''){
    timed = [props.filters.start_Date, props.filters.end_Date];
  }
  let filters = [];

  if (Array.isArray(props.filters.selectedCompared) && props.filters.selectedCompared.length > 0) {
    filters.push({
      member: "streams_part.track_name",
      operator: "contains",
      values: props.filters.selectedCompared,
    });
  }
  
  if (Array.isArray(props.filters.selectedCountries) && props.filters.selectedCountries.length > 0) {
    filters.push({
      member: "streams_part.country",
      operator: "contains",
      values: props.filters.selectedCountries,
    });
  }
    return (
      <QueryRenderer
        query={{
          measures: props.query.customdashboard__measures,
          timeDimensions: [
            {
              dimension: 'streams_part.datetimestamp',
              granularity: 'day',
              dateRange: timed,
            },
          ],
          order: {
            'streams_part.count': 'desc',
          },
          dimensions: props.query.customdashboard__dimensions,
          "filters": filters
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet, error, loading }) => {
          if (loading) return <img src={spining} alt="loading" style={{ width: '100px', height: '100px' }} />;
          if (error) return <div>{error.toString()}</div>;

          if (!resultSet) {
            return <img src={spining} alt="loading" style={{ width: '100px', height: '100px' }} />;
          }

          const series = resultSet.series().map(s => ({
            name: s.title,
            data: s.series.map(point => point.value)
          }));
          if (!series) {
            return <div>No data to display.</div>;
          }
          const options = {
            chart: {
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },
            xaxis: {
              categories: resultSet.series().map(s => s.series.map(point => point.x)).flat(),
              type: 'datetime',
            },
            yaxis: {
              labels: {
                formatter: (val) => format(val),
              },
            },
            fill: {
              colors:colors,
            },
            colors: colors,
            legend: {
              show: props.query.customdashboard__legend == 1 ? true : false,
            },
            tooltip: {
              enabled: props.query.customdashboard__tooltip == 1 ? true : false,
            },
          };

          return (
            <ResponsiveContainer width="100%"  height={props.height}>
                <ApexCharts
                options={options}
                series={series}
                type="line"
                height={350}
                />
            </ResponsiveContainer>
          );
        }}
      />
    );
}
