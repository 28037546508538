import React, { useState } from 'react';
import { QueryRenderer } from '@cubejs-client/react';
import cubejsApi from '../../../cubejs-config.js';
import spining from '../../../loading.gif';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import './DataTable.css';

export default function DataTable(props) {
  let timed = props.query.customdashboard__filters;
  if (props.filters.start_Date !== undefined && props.filters.start_Date !== '') {
    timed = [props.filters.start_Date, props.filters.end_Date];
  }
  let filters = [];

  if (Array.isArray(props.filters.selectedCompared) && props.filters.selectedCompared.length > 0) {
    filters.push({
      member: "streams_part.track_name",
      operator: "contains",
      values: props.filters.selectedCompared,
    });
  }
  
  if (Array.isArray(props.filters.selectedCountries) && props.filters.selectedCountries.length > 0) {
    filters.push({
      member: "streams_part.country",
      operator: "contains",
      values: props.filters.selectedCountries,
    });
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <QueryRenderer
      query={{
        measures: props.query.customdashboard__measures,
        dimensions: props.query.customdashboard__dimensions,
        timeDimensions: [{
          dimension: 'streams_part.datetimestamp',
          dateRange: timed
        }],
        "filters": filters
      }}
      cubejsApi={cubejsApi}
      render={({ resultSet, error, loading }) => {
        if (loading) return <img src={spining} alt="Loading..." style={{ width: '100px', height: '100px' }} />;
        if (error) return <div>{error.toString()}</div>;

        if (!resultSet) {
          return <img src={spining} alt="Loading..." style={{ width: '100px', height: '100px' }} />;
        }

        const columns = [...props.query.customdashboard__dimensions, ...props.query.customdashboard__measures];
        const data = resultSet.tablePivot();

        if (!data.length) {
          return <div>No data available</div>;
        }

        const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return (
          <>
            <TableContainer component={Paper} style={{ maxHeight: 440 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((col, index) => (
                      <TableCell key={index} className="table-header"><b>{col.replace('streams_part.', '')}</b></TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {columns.map((col, colIndex) => (
                        <TableCell key={colIndex}>{row[col]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        );
      }}
    />
  );
}
