import React, { useEffect, useRef, useState } from 'react';
import './Chatbot.css';

const Chatbot = ({ onUserInput }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const onUserInputRef = useRef(onUserInput);
  const chatBubbleRef = useRef(null);

  useEffect(() => {
    onUserInputRef.current = onUserInput;
  }, [onUserInput]);

  useEffect(() => {
    // Append Dialogflow CX stylesheet
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/themes/df-messenger-default.css';
    document.head.appendChild(link);

    // Append Dialogflow CX script
    const script = document.createElement('script');
    script.src = 'https://analytics.mc-test.org/df-messenger.js';
    document.body.appendChild(script);
    
    // Clear session storage on page refresh
    window.sessionStorage.clear();

    const handleMessage = (event) => {
      console.log('Message event received:', event);
      if (event.detail && event.detail.raw && event.detail.raw.queryResult) {
        const parameters = event.detail.raw.queryResult.parameters;

        // Extract the required values
        const chartType = parameters.charttype ? parameters.charttype : null;
        const dimension = parameters.dimension ? parameters.dimension : null;
        const measures = parameters.measures ? parameters.measures : null;

        // Construct the result object
        const result = {
          chartType,
          dimension,
          measures
        };
        console.log('result:', result);

        // Pass the extracted parameters to the parent component
        onUserInputRef.current(result);
      } else {
        console.log('Unexpected event structure:', event);
      }
    };

    // Wait for the script to load before adding the event listener
    script.onload = () => {
      window.addEventListener('df-response-received', handleMessage);
      
      // Set up MutationObserver to detect when chat is opened/closed
      const dfMessenger = document.querySelector('df-messenger');
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'expanded') {
            setIsChatOpen(dfMessenger.getAttribute('expanded') === 'true');
          }
        });
      });

      observer.observe(dfMessenger, { attributes: true });
    };

    // Clean up
    return () => {
      window.removeEventListener('df-response-received', handleMessage);
      if (link.parentNode) link.parentNode.removeChild(link);
      if (script.parentNode) script.parentNode.removeChild(script);
    };
  }, []);

  return (
    <>
      <df-messenger
        location="us-central1"
        project-id="futurefous"
        agent-id="3711c92b-9bff-4b9a-982c-f6daa5e77ffe"
        language-code="en"
        max-query-length="-1"
        >
        <df-messenger-chat-bubble
          chat-title="Build Chart With Luna"
          ref={chatBubbleRef}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}>
        </df-messenger-chat-bubble>
      </df-messenger>
      {!isChatOpen && isHovering && (
        <div className="message-element">
          <div className="message-container">
            <img src="https://routenotetest.com/rn22/use_luna_image/iconai-sparkle.svg" alt="AI Sparkle" className="message-icon" />
            <div className="message-text-primary">Ask Luna</div>
          </div>
          <div className="message-text-secondary">Hi! I'm Luna. Click to generate charts and analyze your data effortlessly.</div>
        </div>
      )}
    </>
  );
};

export default Chatbot;