import React, { useState } from "react";
import { MenuItem, Typography } from "@mui/material";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from "../loading";

export default function DeleteChart({ chartID, ...props }) {
    const navigate = useNavigate();
    const [chartID1, setChartID] = useState(chartID);
    const [Load, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://analytics.mc-test.org/saveChart.php', {
                action: 'Delete',
                chartid: chartID1,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Adjust content type if needed
                },
            });
            if (response) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>{Load ? (<>
        <Loading />
        <MenuItem onClick={handleDelete}>
            <Typography>Delete</Typography>
        </MenuItem>
        </>) : (
        <MenuItem onClick={handleDelete}>
            <Typography>Delete</Typography>
        </MenuItem>)}</>
    );
}
